import React from 'react'

const SkiLessonsPage = () => (
  <>
  <h1>Redirect</h1>
  {typeof window !== 'undefined' ? window.location = '/lessons-guiding' : ''}
  </>
);

export default SkiLessonsPage;
